<div class="flex justify-center">
    <div class="qr-code-container pt-0 m-auto" *ngIf="companyType === '1' && !idvCheckCompleted ">
        <h2 class="title">Identity Verification Required</h2>
        <p class="subtitle">Please proceed with the identification check to finalize the process.</p>
        <ion-button class="btn__primary mr-5" (click)="openTrusmatic()">Proceed</ion-button>
        <div class="flex items-center justify-center">
            <p class="copy-text">Copy link</p>
            <ion-icon
                *ngIf="!isLinkCopied"
                name="copy-outline"
                class="ml-3 cursor-pointer"
                (click)="copyLink()"
                size="small"></ion-icon>
            <ion-icon
                *ngIf="isLinkCopied"
                name="checkmark-circle-outline"
                class="ml-3 text-green-500"
                size="large"></ion-icon>
        </div>
    </div>
    <div class="qr-code-container" *ngIf="companyType !== '1' || (companyType === '1' && idvCheckCompleted)">
        <ion-icon
            src="assets/icon/green-check.svg"
            size="large"
            style="zoom: 2"
            class="bg-green-600 text-white rounded-full"></ion-icon>
        <h2 class="text-black">We have received your application</h2>
        <p>
            We strive to review applications within 24-48 hours. Once approved, you will receive a notification email.
        </p>
    </div>
</div>
