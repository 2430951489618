<ng-container>
    <form [formGroup]="businessInfoForm" class="max-width flex flex-col items-center">
        <ion-row>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                *ngIf="companyTypeId === '1'"
                formGroupName="personalInfo"
                class="pr-[7px]">
                <app-form-field label="label.first_name" required>
                    <mat-form-field class="max-w-[616px]">
                        <input matInput formControlName="firstName" name="apAccount" required />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                *ngIf="companyTypeId === '1'"
                formGroupName="personalInfo"
                class="pl-[7px]">
                <app-form-field label="label.last_name" required>
                    <mat-form-field>
                        <input matInput required formControlName="lastName" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col size="12" *ngIf="companyTypeId !== '1'" formGroupName="companyInfo">
                <app-form-field label="label.legal_name" required>
                    <mat-form-field>
                        <input matInput formControlName="companyName" name="legal" required />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                *ngIf="companyTypeId !== '1'"
                formGroupName="companyInfo"
                class="pr-[7px]">
                <app-form-field label="label.phone_number" required>
                    <mat-form-field>
                        <input matInput required formControlName="phoneNumber" mask="(000) 000-0000" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="companyInfo"
                *ngIf="companyTypeId !== '1'">
                <app-form-field label="label.nature_of_business" required>
                    <mat-form-field>
                        <mat-select
                            formControlName="kycEmploymentSectorId"
                            panelClass="mat-select-option-panal"
                            disableOptionCentering>
                            <mat-option
                                *ngFor="let businessType of businessTypes"
                                value="{{ businessType.kycEmploymentSectorId }}"
                                >{{ businessType.value }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="companyInfo"
                *ngIf="
                    companyTypeId !== '1' &&
                    businessInfoForm.get('companyInfo').get('kycEmploymentSectorId').value === '21'
                "
                class="pr-[7px]">
                <app-form-field label="label.other" required>
                    <mat-form-field>
                        <input matInput required formControlName="otherEmploymentSector" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="companyTypeId === '1'"
                class="pr-[7px]">
                <app-form-field label="label.dob" required>
                    <mat-form-field>
                        <input
                            matInput
                            [max]="today"
                            [matDatepicker]="dateOfBirth"
                            placeholder="yyyy-mm-dd"
                            required
                            name="dateOfBirth"
                            formControlName="dateOfBirth" />

                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfBirth></mat-datepicker>
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="companyTypeId === '1'"
                class="pl-[7px]">
                <app-form-field label="label.occupation" required>
                    <mat-form-field>
                        <input matInput required formControlName="occupation" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="companyTypeId === '1'"
                class="pr-[7px]">
                <app-form-field label="label.gender" required>
                    <mat-form-field>
                        <mat-select
                            formControlName="gender"
                            panelClass="mat-select-option-panal"
                            disableOptionCentering>
                            <mat-option *ngFor="let options of genders" value="{{ options.name }}">{{
                                options.name
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </app-form-field>
            </ion-col>

            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="companyTypeId === '1'"
                class="pl-[7px]">
                <app-form-field label="label.industry" required>
                    <mat-form-field>
                        <mat-select
                            formControlName="kycEmploymentSectorId"
                            panelClass="mat-select-option-panal"
                            disableOptionCentering>
                            <mat-option
                                *ngFor="let employmentSector of employmentSectors"
                                value="{{ employmentSector.kycEmploymentSectorId }}"
                                >{{ employmentSector.value }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="
                    companyTypeId === '1' &&
                    businessInfoForm.get('personalInfo').get('kycEmploymentSectorId').value === '21'
                "
                class="pr-[7px]">
                <app-form-field label="label.other" required>
                    <mat-form-field>
                        <input matInput required formControlName="otherEmploymentSector" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="companyTypeId === '1'"
                class="pr-[7px]">
                <app-form-field label="label.nationality" required>
                    <mat-form-field>
                        <mat-select
                            formControlName="nationalityAlpha2Code"
                            panelClass="mat-select-option-panal"
                            disableOptionCentering>
                            <mat-option *ngFor="let option of nationalitiesOptions" value="{{ option.alpha2Code }}">
                                {{ option.nationality }} ({{ option.alpha2Code }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="personalInfo"
                *ngIf="companyTypeId === '1'"
                class="pr-[7px]">
                <app-form-field label="label.phone_number">
                    <mat-form-field>
                        <input matInput formControlName="phoneNumber" mask="(000) 000-0000" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col size="12" formGroupName="address">
                <ng-container [ngSwitch]="companyTypeId">
                    <ng-container *ngSwitchCase="1">
                        <app-form-field label="{{ getAddressLabel() }} " required>
                            <location-search-v2
                                [required]="true"
                                [isReadOnly]="disableForm"
                                [completeAddress]="businessInfoForm.get('address').get('fullAddress').value"
                                label="set_address_using_google_canada"
                                [fixedCountry]="'CA'"
                                (onPlaceChanged)="locationResults($event)"></location-search-v2>
                        </app-form-field>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <app-form-field label="{{ getCompanyType() + ' Address' }}" required>
                            <location-search-v2
                                [required]="true"
                                [completeAddress]="businessInfoForm.get('address').get('fullAddress').value"
                                [isReadOnly]="disableForm"
                                label="set_address_using_google_canada"
                                [fixedCountry]="'CA'"
                                (onPlaceChanged)="locationResults($event)"></location-search-v2>
                        </app-form-field>
                    </ng-container>
                </ng-container>
            </ion-col>
            <ng-container *ngIf="this.businessInfoForm.get('address').get('fullAddress').value">
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="12"
                    formGroupName="address"
                    class="pr-[7px]">
                    <app-form-field label="label.build_street_number" required>
                        <mat-form-field>
                            <input matInput required formControlName="buildingNumber" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="address"
                    class="pl-[7px]">
                    <app-form-field label="label.street_name" required>
                        <mat-form-field>
                            <input matInput required formControlName="streetName" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="address"
                    class="pr-[7px]">
                    <app-form-field label="label.city" required>
                        <mat-form-field>
                            <input matInput required formControlName="city" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="address"
                    class="pl-[7px]">
                    <app-form-field label="label.province" required>
                        <mat-form-field>
                            <input matInput required formControlName="province" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="address"
                    class="pr-[7px]">
                    <app-form-field label="label.postal_code" required>
                        <mat-form-field>
                            <input matInput required formControlName="postalCode" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="address"
                    class="pl-[7px]">
                    <app-form-field label="label.country" required>
                        <mat-form-field>
                            <input matInput required formControlName="country" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
            </ng-container>
            <ion-col size="12" *ngIf="companyTypeId !== '1'">
                <div class="mailing-address pr-4">
                    <p>{{ 'label.mailing_address' | translate }} *</p>
                    <mat-checkbox
                        (change)="trackCheckboxClick()"
                        [color]="'primary'"
                        class="checkbox-margin"
                        class=""
                        [checked]="showMailingAddress && companyTypeId !== '1'"
                        [disabled]="disableForm">
                        Same as {{ getCompanyType() + ' Address' }}
                    </mat-checkbox>
                </div>
            </ion-col>
        </ion-row>
        <ion-row> </ion-row>
        <ion-row *ngIf="!showMailingAddress && companyTypeId !== '1'">
            <ion-col size="12" formGroupName="mailingAddress">
                <app-form-field label="">
                    <location-search-v2
                        [required]="googleAddress"
                        label="set_address_using_google_canada"
                        [isReadOnly]="disableForm"
                        [fixedCountry]="'CA'"
                        (onPlaceChanged)="locationResultsForMailingAddress($event)"
                        [completeAddress]="
                            this.businessInfoForm.get('mailingAddress').get('fullAddress').value
                        "></location-search-v2>
                </app-form-field>
            </ion-col>
            <ng-container *ngIf="this.businessInfoForm.get('address').get('fullAddress').value">
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="mailingAddress"
                    class="pr-[7px]"
                    location>
                    <app-form-field label="label.build_street_number" required>
                        <mat-form-field>
                            <input matInput required formControlName="buildingNumber" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="mailingAddress"
                    class="pl-[7px]">
                    <app-form-field label="label.street_name" required>
                        <mat-form-field>
                            <input matInput required formControlName="streetName" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="mailingAddress"
                    class="pr-[7px]">
                    <app-form-field label="label.city" required>
                        <mat-form-field>
                            <input matInput required formControlName="city" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="mailingAddress"
                    class="pl-[7px]">
                    <app-form-field label="label.province" required>
                        <mat-form-field>
                            <input matInput required formControlName="province" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="mailingAddress"
                    class="pr-[7px]">
                    <app-form-field label="label.postal_code" required>
                        <mat-form-field>
                            <input matInput required formControlName="postalCode" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
                <ion-col
                    size="6"
                    size-sm="12"
                    size-xs="12"
                    size-lg="6"
                    size-xs="12"
                    size-md="6"
                    formGroupName="mailingAddress"
                    class="pl-[7px]">
                    <app-form-field label="label.country" required>
                        <mat-form-field>
                            <input matInput required formControlName="country" />
                            <mat-error appFieldErrors></mat-error>
                        </mat-form-field>
                    </app-form-field>
                </ion-col>
            </ng-container>
        </ion-row>
        <ion-row>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                *ngIf="companyTypeId !== '1'"
                class="pr-[7px]">
                <app-form-field
                    label="label.shareholding_document"
                    [showButtonInfo]="true"
                    [matTooltipText]="shareholdingDocumentInfo()"
                    required>
                    <div
                        class="border p-4 flex items-center gap-4 mt-1 justify-center"
                        style="border-color: #7fe26a; border-radius: 6px; cursor: pointer"
                        *ngIf="filesToSend.length === 0 || !disableForm"
                        (click)="triggerPhotoUpload()">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none">
                                <path
                                    d="M17.9776 9.01106C17.985 9.01102 17.9925 9.01101 18 9.01101C20.4853 9.01101 22.5 11.0294 22.5 13.5193C22.5 15.8398 20.75 17.7508 18.5 18M17.9776 9.01106C17.9924 8.84606 18 8.67896 18 8.51009C18 5.46695 15.5376 3 12.5 3C9.62324 3 7.26233 5.11267 7.02042 8.03192M17.9776 9.01106C17.8753 10.1476 17.4286 11.1846 16.7428 12.0165M7.02042 8.03192C4.48398 8.27373 2.5 10.2139 2.5 13.0183C2.5 15.4417 4.21776 17.4632 6.5 17.9273M7.02042 8.03192C7.17826 8.01687 7.33823 8.00917 7.5 8.00917C8.62582 8.00917 9.66474 8.38194 10.5005 9.01101"
                                    stroke="#39E863"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M12.5 13V21M12.5 13C11.7998 13 10.2915 14.9943 10 15.5M12.5 13C13.2002 13 14.5085 14.9943 15 15.5"
                                    stroke="#39E863"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        <span style="color: #7fe26a"> Upload Shareholding Document </span>
                    </div>
                    <file-uploader
                        tappable
                        #fileUploader
                        *ngIf="!disableForm"
                        [(triggerExternalClick)]="triggerExternalClickCount"
                        [options]="uploadedFilesOptions"
                        [uploadedFiles]="filesToSend"
                        [disabled]="disableForm"
                        [allowedFileTypes]="'image/jpeg, image/png, application/pdf'"
                        [inputData]="shareholderUploadReq"
                        (afterUpload)="afterUpload($event, 1)"></file-uploader>
                </app-form-field>
                <div *ngIf="filesToSend.length > 0" class="pt-4 pb-4 flex flex-col justify-center">
                    <div
                        class="flex items-center justify-between gap-3 text-[#666]"
                        *ngFor="let file of filesToSend; let i = index">
                        <div style="display: flex; align-items: center; gap: 10px; flex: 1 0 0; max-width: 80%">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                style="flex-shrink: 0">
                                <path
                                    d="M19.5 12.0001V13.5001C19.5 17.6422 16.1421 21.0001 12 21.0001C7.85786 21.0001 4.5 17.6422 4.5 13.5001V8C4.5 5.23858 6.73858 3 9.5 3C12.2614 3 14.5 5.23858 14.5 8V13.5C14.5 14.8807 13.3807 16 12 16C10.6193 16 9.5 14.8807 9.5 13.5V9.5"
                                    stroke="#4ADE80"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <span style="color: #7fe26a; white-space: wrap; overflow: hidden">
                                {{ file['originalNameWithExt'] }}</span
                            >
                        </div>

                        <ion-icon
                            class="ml-auto cursor-pointer text-2xl"
                            color="danger"
                            name="trash"
                            (click)="removeFile(i, 1, filesToSend[i].kycDocumentId)"
                            *ngIf="!disableForm"></ion-icon>
                    </div>
                </div>
                <mat-error *ngIf="triggerDocumentUploadError && !isShareHoldingDocumentsUploaded()"
                    >*Please upload the shareholding document*</mat-error
                >
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                *ngIf="companyTypeId !== '1'"
                class="pl-[7px]">
                <app-form-field
                    label="label.record_of_existence"
                    [showButtonInfo]="true"
                    [matTooltipText]="recordsOfexistenceInfo()"
                    required>
                    <div
                        class="border p-4 flex items-center gap-4 mt-1 justify-center"
                        style="border-color: #7fe26a; border-radius: 6px; cursor: pointer"
                        *ngIf="!disableForm"
                        (click)="triggerRecordOfExistenceUpload()">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none">
                                <path
                                    d="M17.9776 9.01106C17.985 9.01102 17.9925 9.01101 18 9.01101C20.4853 9.01101 22.5 11.0294 22.5 13.5193C22.5 15.8398 20.75 17.7508 18.5 18M17.9776 9.01106C17.9924 8.84606 18 8.67896 18 8.51009C18 5.46695 15.5376 3 12.5 3C9.62324 3 7.26233 5.11267 7.02042 8.03192M17.9776 9.01106C17.8753 10.1476 17.4286 11.1846 16.7428 12.0165M7.02042 8.03192C4.48398 8.27373 2.5 10.2139 2.5 13.0183C2.5 15.4417 4.21776 17.4632 6.5 17.9273M7.02042 8.03192C7.17826 8.01687 7.33823 8.00917 7.5 8.00917C8.62582 8.00917 9.66474 8.38194 10.5005 9.01101"
                                    stroke="#39E863"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M12.5 13V21M12.5 13C11.7998 13 10.2915 14.9943 10 15.5M12.5 13C13.2002 13 14.5085 14.9943 15 15.5"
                                    stroke="#39E863"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        <span style="color: #7fe26a"> Upload Record of Existence </span>
                    </div>
                    <file-uploader
                        tappable
                        #RecordOfExistencefileUploader
                        [disabled]="disableForm"
                        [(triggerExternalClick)]="triggerRecordOfExistencefileClickCount"
                        [options]="uploadedFilesOptions"
                        [uploadedFiles]="recordOfExistencefile"
                        [allowedFileTypes]="'image/jpeg, image/png, application/pdf'"
                        [inputData]="recordOfExistenceUploadReq"
                        (afterUpload)="afterUpload($event, 2)"></file-uploader>
                </app-form-field>
                <div *ngIf="recordOfExistencefile.length > 0" class="pt-4 pb-4 flex flex-col justify-center">
                    <div
                        class="flex items-center justify-between gap-3 text-[#666]"
                        *ngFor="let file of recordOfExistencefile; let i = index">
                        <div style="display: flex; align-items: center; gap: 10px; flex: 1 0 0; max-width: 80%">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                style="flex-shrink: 0">
                                <path
                                    d="M19.5 12.0001V13.5001C19.5 17.6422 16.1421 21.0001 12 21.0001C7.85786 21.0001 4.5 17.6422 4.5 13.5001V8C4.5 5.23858 6.73858 3 9.5 3C12.2614 3 14.5 5.23858 14.5 8V13.5C14.5 14.8807 13.3807 16 12 16C10.6193 16 9.5 14.8807 9.5 13.5V9.5"
                                    stroke="#4ADE80"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <span style="color: #7fe26a; white-space: wrap; overflow: hidden">
                                {{ file['originalNameWithExt'] }}</span
                            >
                        </div>

                        <ion-icon
                            class="ml-auto cursor-pointer text-2xl"
                            *ngIf="!disableForm"
                            color="danger"
                            name="trash"
                            (click)="removeFile(i, 2, recordOfExistencefile[i].kycDocumentId)"></ion-icon>
                    </div>
                </div>
                <mat-error *ngIf="triggerDocumentUploadError && !isRecordOfExistencefile()"
                    >*Please upload record of existence documents*</mat-error
                >
            </ion-col>
            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="companyInfo"
                *ngIf="companyTypeId !== '1'"
                class="pr-[7px]">
                <app-form-field [label]="'label.document_date' | translate">
                    <mat-form-field>
                        <input
                            matInput
                            [max]="today"
                            [matDatepicker]="dateOfBirth"
                            placeholder="yyyy-mm-dd"
                            name="dateOfBirth"
                            formControlName="documentDate"
                            [readonly]="true" />

                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfBirth></mat-datepicker>
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>

            <ion-col
                size="6"
                size-sm="12"
                size-xs="12"
                size-lg="6"
                size-xs="12"
                size-md="6"
                formGroupName="companyInfo"
                *ngIf="companyTypeId !== '1'"
                class="pl-[7px]">
                <app-form-field label="label.incorporation_date">
                    <mat-form-field>
                        <input
                            matInput
                            [max]="today"
                            [matDatepicker]="dateOfBirth"
                            placeholder="yyyy-mm-dd"
                            name="dateOfBirth"
                            formControlName="incorporationDate" />

                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfBirth></mat-datepicker>
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col size="12" formGroupName="companyInfo" *ngIf="companyTypeId !== '1'" class="pl-[7px]">
                <app-form-field label="label.document_issue_location">
                    <mat-form-field>
                        <mat-select
                            formControlName="documentIssueLocation"
                            panelClass="mat-select-option-panal"
                            disableOptionCentering>
                            <mat-option *ngFor="let province of canadianProvincesOptions" [value]="province">{{
                                province
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
        </ion-row>
    </form>
</ng-container>
